import { combineReducers } from "redux";
import PeopleReducer from "./people_reducer";
import TabReducer from "./tab_reduer";
import SubTabReducer from "./sub_tab_reducer";
import ProductReducer from "./product_reducer";
import EditIDReducer from "./reservation_id_reducer";
import TransactionReducer from "./transaction_reducer";
import AffiliateReducer from "./affiliate_reducer";
import NoteReducer from "./note_reducer";
import ReservationStatusReducer from "./reservation_status_reducer";

const rootReducer = combineReducers({
  people: PeopleReducer,
  product: ProductReducer,
  transaction: TransactionReducer,
  affiliate: AffiliateReducer,
  note: NoteReducer,
  tab: TabReducer,
  sub_tab: SubTabReducer,
  editID: EditIDReducer,
  editStatus: ReservationStatusReducer
});

export default rootReducer;
