const initialState = null;

const EditIDReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setEditID":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export default EditIDReducer;
