const initialState = [0, 0, 0, 0, 0];

const SubTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setSubTab":
      state[action.payload.tabIndex] = action.payload.index;
      return [...state];
    default:
      return state;
  }
};
export default SubTabReducer;
