export const USD_ID = 236;
export const MMK_ID = 153;

export const INTERFACE_DATE = "MM/DD/YYYY";
export const INTERFACE_DATETIME = "MM/DD/YYYY HH:mm:ss";
export const MYSQL_DATE = "YYYY-MM-DD";
export const MYSQL_DATETIME = "YYYY-MM-DD HH:mm:ss";
export const TOKEN_TYPE = "token_type";
export const AUTH_TOKEN = "access_token";

export const tabMapping = [
  {
    id: 0,
    name: "people",
    type: "people"
  },
  {
    id: 1,
    name: "booking",
    type: "product"
  },
  {
    id: 2,
    name: "transaction",
    type: "payment"
  },
  {
    id: 3,
    name: "affiliate-fees",
    type: "affiliate"
  },
  {
    id: 4,
    name: "note",
    type: "note"
  },
  {
    id: 5,
    name: "finance",
    type: "finance"
  }
];
