import moment from "moment";
import _ from "lodash";

export default (initstate, name) => (state = initstate, action) => {
  switch (action.type) {
    case `add${name}`:
      return _.uniq([...state, action.payload]);
    case `update${name}`:
      state[action.payload.editIndex] = action.payload.data;
      return [...state];
    case `set${name}`:
      let updateResult = JSON.parse(JSON.stringify(action.payload));
      return updateResult;
    case `delete${name}`:
      state[action.payload.editIndex]["deletedAt"] = moment().format(
        "YYYY-MM-DD"
      );
      return [...state];
    case `remove${name}`:
      let removeList = JSON.parse(JSON.stringify(state));
      removeList.splice(action.payload, 1);
      removeList.length === 0 && (removeList = [{ id: null }]);
      return removeList;
    case `reset${name}`:
      return [{ id: null }];
    default:
      return [...state];
  }
};
